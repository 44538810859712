<template>
  <PageWithLayout>
    <!-- <div v-scroll-lock="isModalMode" class="purchase_layer"> -->
    <!-- <div class="inner_comm_layer inner_print_layer"> -->
    <!-- 글로싸인 미리보기 영역 -->
    <div class="area_iframe">
      <div id="iframe" />
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </PageWithLayout>
</template>

<script>
// import Sticky from '@/components/layout/content/Sticky.vue'
import PageWithLayout from "@/components/layout/PageWithLayout";
export default {
  name: "TemplatePreviewWindow",
  components: {
    PageWithLayout,
  },
  props: {},
  data() {
    return {
      isModalMode: true,
      previewId: "",
    };
  },
  created() {
    this.previewId = this.$route.query.previewId;
  },
  mounted() {
    // var glosignToken = 'v31TF2Yr8jw373kkVImM5rgHL';
    let options = { container: "iframe", viewerType: "preview", previewId: this.previewId };
    let viewer = new glosapi.viewer("y16X4tFyJNvScb2JS8KXchAm6").setOptions(options);

    viewer.open();

    viewer.setCallback((result) => {
      if (result.action == "complete") {
        //this.$emit('onClickClose');
      } else if (result.action == "close") {
        window.close();
      }
    });
  },
  beforeDestroy() {
    this.isModalMode = false;
  },
  // methods: {
  //   onClickClose() {
  //     this.$emit( 'onClickClose' );
  //   }
  // }
};
</script>
<style scoped>
/* -- 레이어 팝업 -- */
.purchase_layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9990;
  min-width: 320px;
  font-size: 0;
  text-align: center;
  white-space: nowrap;
}
/* padding:50px 0;background-color:rgba(34,34,34,.6); */
.purchase_layer:after {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
/* 공통 */
.purchase_layer /deep/ .inner_comm_layer {
  display: inline-block;
  overflow: hidden;
  position: relative;
  border-radius: 2px;
  background-color: #fff;
  white-space: normal;
  vertical-align: middle;
}
.purchase_layer /deep/ .inner_comm_layer .body_section > .area_view + .group_btn {
  padding: 32px 0 8px;
  text-align: right;
}
.purchase_layer /deep/ .inner_comm_layer .layer_body > .group_btn {
  padding-top: 32px;
  text-align: center;
}
.purchase_layer /deep/ .inner_comm_layer .layer_body > .group_btn button + button {
  margin-left: 8px;
}
.purchase_layer /deep/ .section_sticky {
  height: 80px;
}
.purchase_layer /deep/ .section_sticky .inner_section {
  padding: 20px 0;
}
.purchase_layer /deep/ .section_sticky .box_sticky {
  box-shadow: 0 -2px 8px 0 rgba(34, 34, 34, 0.15);
}
/* .purchase_layer /deep/ .inner_table_layer .tbl_list{overflow-x:hidden;overflow-y:auto} */
.purchase_layer /deep/ .inner_table_layer .tbl_comm thead th {
  text-align: center;
}
.purchase_layer /deep/ .inner_table_layer .desc_alert {
  margin-top: 16px;
  padding: 16px 34px 15px;
  border: 1px solid #5551ce;
  font-size: 12px;
  line-height: 18px;
  background-color: #f6f6fc;
  color: #5551ce;
}
.purchase_layer /deep/ .inner_table_layer .desc_alert.tc_red {
  border: 1px solid #f8626c;
  background-color: #fef7f7;
}
.purchase_layer .area_view + .area_view {
  margin-top: 32px;
}

.purchase_layer /deep/ .inner_print_layer {
  overflow: hidden;
  position: static;
  width: 1090px;
  height: 100%;
  text-align: initial;
}
/* .purchase_layer /deep/ .inner_print_layer .layer_head{} */
.purchase_layer /deep/ .inner_print_layer .section_comm {
  padding-bottom: 0;
}
.purchase_layer /deep/ .inner_print_layer .tit_layer {
  padding: 24px 48px 17px;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: #888;
}
.purchase_layer /deep/ .inner_print_layer .ico_preview {
  margin: 3px 8px 0 0;
}
.purchase_layer /deep/ .inner_print_layer .layer_body {
  overflow-y: auto;
  height: calc(100% - 60px);
}

.purchase_layer /deep/ .inner_print_layer .section_comm {
  width: auto;
}
.purchase_layer /deep/ .inner_print_layer .inner_section {
  width: 100%;
}
</style>
